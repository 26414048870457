import React, {useEffect, useState} from 'react';
import style from './PrivacyPolicy.module.css';
import SEO from '../../components/ui/SEO';
import DOMPurify from 'dompurify';

const PrivacyPolicy = () => {

    const [data, setData] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        fetch(`${baseUrl}/api/v1/privacy-policy`)
            .then(response => response.json())
            .then(data => setData(data?.data))
            .catch(error => console.log(error));
    }, []);

    return (
        <>
            <SEO
                title="Privacy Policy"
                url="/privacy-policy"
            />
            <div className={`mt-10 container m-auto max-w-[1260px] px-5`}>

                <div className={` ${style.size}`}
                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.description)}}
                />

            </div>
        </>
    );
};

export default PrivacyPolicy;