import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import StudentFeadback from './StudentFeadback';

const StudentFeadbackCoursePage = ({ course }) => {
  const [comment, setComment] = useState('');
  const [viewComment, setViewComment] = useState('');


  const [cookies] = useCookies(['EduTvAuth']);
  const auth = cookies?.EduTvAuth;
  const { user } = auth || {};
  if (user === undefined) {
    console.log('user nai')
  } else {
    console.log('user ache')
  }
  console.log(user)
  const token = auth?.accessToken;
  const baseUrl = process.env.REACT_APP_BASE_URL;


  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/v1/courses/reviews/${course?.id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Replace with your actual bearer token
        },
      });

      if (response.ok) {
        const jsonData = await response.json();
        console.log(jsonData?.data);
        setViewComment(jsonData?.data);
      } else {
        throw new Error('Error fetching data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleRefetch = () => {
    fetchData(); // Call fetchData to refetch data
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData();
    // fetchData();
    setComment('');
    handleRefetch();
  };


  const postData = async () => {
    try {
      const response = await axios.post(`${baseUrl}/api/v1/account/course/review`, {
        course_id: course?.id,
        user_id: user?.id,
        comment: comment
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      console.log(response.data);


    } catch (error) {
      console.error(error);
    }
  };


  return (
    <div>
      <div className='mb-3'>

        {

          user !== undefined &&
          <>
            <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>Give Feadback </h1>
            <form action="" onSubmit={handleSubmit}>
              <label htmlFor="comment"></label>
              <input type="text" className="py-2 w-full rounded-lg border outline-none px-2" value={comment} onChange={handleChange} />
              <button type="submit" className='bg-orange-500 py-2 px-3 text-white mt-2 mb-2 rounded-md'>submit</button>
            </form>
          </>
        }
        <hr />

        <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>{viewComment?.length} Student Feadback </h1>
        <div>
          {viewComment &&

            viewComment?.map(comments => <StudentFeadback key={comments?.id} comments={comments} />)
          }
        </div>
      </div>

    </div>
  );
};

export default StudentFeadbackCoursePage;