import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthRouteProvider from '../components/route/AuthRouteProvider'
import UserCourse from '../pages/Dashboard/UserCourse'
import Dashboard from '../pages/account/dashboard/Dashboard'
import UserProfile from '../pages/Dashboard/UserProfile'
import ExamPage from '../pages/Exam/ExamPage'
import WishList from '../pages/WishList/WishList'
import Checkout from '../pages/Checkout/Checkout'
import Cart from '../pages/Cart/Cart'
import EnrollmentSuccess from '../pages/Checkout/EnrollmentSuccess'
import NotFound from '../components/notFound/NotFound'
import Invoice from '../pages/account/enrollment/Invoice';
import EnrollmentList from '../pages/account/enrollment/EnrollmentList';
import CourseList from '../pages/account/course/CourseList'
import Intro from '../components/account/course/playing/Intro'
import Lesson from '../components/account/course/playing/Lesson'
import CurrentPlaying from '../pages/account/course/CurrentPlaying'
import EnrollmentSuccessFree from '../pages/Checkout/EnrollmentSuccessFree'
import ChangePassword from '../pages/Dashboard/ChangePassword'
import CertificatePage from '../pages/CertificatePage/CertificatePage'
import CheckoutAamarPay from '../pages/Checkout/CheckoutAamarPay'


const AuthRoutes = () => {
    return (
        <AuthRouteProvider>
            <Routes>
                <Route path='/exam-page' element={<ExamPage />} />
                <Route path="/user-courses" element={<UserCourse />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/checkout' element={<Checkout />} />
                <Route path='/enrollment-success/:invoiceNo' element={<EnrollmentSuccess />} />
                <Route path='/enrollment-success' element={<EnrollmentSuccessFree />} />
                <Route path='/payment' element={<CheckoutAamarPay />} />
                





                <Route path='/user/account/wishlist' element={<WishList />} />
                <Route path="/user/account/dashboard" element={<Dashboard />} />
                <Route path="/user/account/courses" element={<CourseList />} />
                <Route path="/user/account/enrollments" element={<EnrollmentList />} />
                <Route path="/user/account/enrollments/:invoiceNo" element={<Invoice />} />
                <Route path="/user/account/profile" element={<UserProfile />} />
                <Route path="/user/account/change-password" element={<ChangePassword />} />
                <Route path="/user/account/certification" element={<CertificatePage />} />
                <Route path="/user/account/courses/:slug/*" element={<CurrentPlaying />}>
                    <Route index element={<Intro />} />
                    <Route path=":topicId/playlists/:lessonId" element={<Lesson />} />
                </Route>
                {/* <Route path="/user/account/courses/:slug/playlists" element={<Intro />} />
                <Route path="/user/account/courses/:slug/playlists/:lessonId" element={<CurrentPlaying />} /> */}       





                <Route path='/*' element={<NotFound />} />
            </Routes>
        </AuthRouteProvider>
    )
    
}

export default AuthRoutes