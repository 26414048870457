import React, {useEffect, useState} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch} from 'react-redux';
import {coursesAPI, useGetCoursesByCategoryQuery} from '../../features/courses/coursesAPI';
import CourseItem from './CourseItem'
import CourseSkeleton from '../ui/Skeleton/CourseSkeleton';
import ChildCategories from "./ChildCategories";

const Courses = ({categorySlug}) => {

    const [feeType, setFeeType] = useState('');
    const {data} = useGetCoursesByCategoryQuery({categorySlug, feeType});
    const {courses, perPage, totalCourses} = data || {};
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const dispatch = useDispatch();

    const fetchMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        if (page > 1) {
            dispatch(
                coursesAPI.endpoints.getMoreCoursesByCategory.initiate({
                    categorySlug,
                    feeType,
                    page,
                })
            );
        }
    }, [feeType, page, dispatch]);


    useEffect(() => {
        if (totalCourses > 0) {
            const more = Math.ceil(totalCourses / perPage) > page;
            setHasMore(more);
        }
    }, [totalCourses, page]);

    const handleSetFeeType = (type = '') => {
        setFeeType(type);
        setPage(1);
        dispatch(coursesAPI.endpoints.getCoursesByCategory.initiate({categorySlug, feeType: type}));
    }

    let courseItems = null;

    if (courses === undefined) {
        courseItems = <CourseSkeleton/>;
    } else if (courses?.length === 0) {
        courseItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (courses?.length > 0) {
        courseItems = <InfiniteScroll
            dataLength={courses.length}
            next={fetchMore}
            hasMore={hasMore}
            // loader={<h4>Loading...</h4>}
            style={{overflow: 'hidden'}}
            // endMessage={
            //     <p style={{ textAlign: 'center' }}>
            //         <b>Yay! You have seen it all</b>
            //     </p>
            // }
        >
            <div className='cards grid sm:grid-cols-2 lg:grid-cols-4 gap-6'>
                {
                    courses.map(course => <CourseItem key={course.id} course={course}/>)
                }
            </div>
        </InfiniteScroll>;
    }

    return (
        <>
            <div className='flex flex-col gap-10 pt-10 mx-auto px-5 max-w-[1265px]'>
                <ChildCategories slug={categorySlug}/>
                <div className='flex items-center gap-2'>
                    <div
                        onClick={() => handleSetFeeType()}
                        className={`
                        ${
                            !feeType
                                ? 'bg-blue-300 text-blue-900 font-medium'
                                : 'bg-blue-100'
                        } 
                        text-sm py-1 px-3 rounded-full cursor-pointer`}
                    >
                        All
                    </div>
                    <div
                        onClick={() => handleSetFeeType('paid')}
                        className={`
                        ${
                            feeType === 'paid'
                                ? 'bg-blue-300 text-blue-900 font-medium'
                                : 'bg-blue-100'
                        } 
                        text-sm py-1 px-3 rounded-full cursor-pointer`}
                    >
                        Premium
                    </div>
                    <div
                        onClick={() => handleSetFeeType('free')}
                        className={`
                        ${
                            feeType === 'free'
                                ? 'bg-blue-300 text-blue-900 font-medium'
                                : 'bg-blue-100'
                        } 
                        text-sm py-1 px-3 rounded-full cursor-pointer`}
                    >
                        Free
                    </div>
                </div>
                {
                    courseItems
                }
            </div>
        </>

    );
}

export default Courses