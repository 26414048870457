import moment from 'moment';
import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import { usePlayLessonQuery } from '../../../../features/playlists/playlistsAPI';
import SEO from '../../../ui/SEO';
import TextSkeleton from '../../../ui/Skeleton/TextSkeleton';
import VideoSkeleton from '../../../ui/Skeleton/VideoSkeleton';
import VideoSkeletonNotFound from '../../../ui/Skeleton/VideoSkeletonNotFound';
import PDFViewer from './PDFViewer';
import axios from 'axios';
import { useCookies } from "react-cookie";
import LessonComment from './LessonComment';
import QuizModal from './QuizModal';
import ReactPlayer from 'react-player';

const Lesson = () => {

    const { slug, lessonId } = useParams();
    const [currentTab, setCurrentTab] = useState('description','quiz');
    const [comment, setComment] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [quizResult, setQuizResult] = useState();

    const handleShowModal = () => {
        setShowModal(!showModal)
    }
console.log(showModal)


    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const { user } = auth || {};

   

    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;


    const { data, isLoading, isError, refetch  } = usePlayLessonQuery(lessonId);
    const { data: lesson } = data || {};
    const {
        id,
        title,
        video,
        attachment,
        description,
        is_video: isVideo,
        is_attachment: isAttachment,
        assignment_description: assignmentDescription,
        is_assignment: isAssignment,
        published_at: publishedAt,
        lesson_review,
        lesson_quiz,
        totalReview,
        url
    } = lesson || {};

    console.log(lesson)

   
    let lessonContent = null;
    useEffect(() => {
        handleRefetch();
        
    }, []);
  
  
    const handleChange = (e) => {
        setComment(e.target.value);
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        postData();
        postResult();
        // fetchData();
        setComment('');
        handleRefetch();
    };


    const postData = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/review`, {
                lesson_id: id,
                user_id: user?.id,
                comment: comment
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

           


        } catch (error) {
            console.error(error);
        }
    };

    const postResult = async () => {
        try {
            const response = await axios.post(`${baseUrl}/api/v1/account/lesson/quiz-result`, {
                lesson_id: id,
                student_id: user?.id,
                total_quiz: lesson_quiz?.length
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response.data.data.id);
            setQuizResult(response.data.data);
        } catch (error) {
            console.error(error);
        }
    };



    const handleRefetch = () => {
        refetch();
      };


    // aws 

    const playerConfig = {
        file: {
          attributes: {
            controlsList: 'nodownload'
          }
        },
      };



    if (isLoading) {
        lessonContent = <>
            <VideoSkeleton />
            <div className='flex flex-col gap-3'>
                <TextSkeleton height='30px' width='100%' />
                <TextSkeleton height='15px' />
            </div>
            <TextSkeleton height='20px' width='100%' />
            <TextSkeleton height='20px' width='100%' />
        </>
    } else if (!isLoading && isError) {
        lessonContent = <>
            <VideoSkeletonNotFound />
        </>
    } else if (!isLoading && !isError && data?.status === 1) {

        lessonContent = <>
            <SEO
                title={title}
                description={`Playing ${title}`}
                url={`/user/account/courses/${slug}/playlists/${lessonId}`}
            />
            <div className='flex flex-col gap-5'>
                {
                    isVideo
                        ? 
                        // <iframe height="400" src={video} title={title} frameBorder="0" allowFullScreen allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'></iframe>
                        // <ReactPlayer url={video} controls />
                        <ReactPlayer
                        url={video}
                        playing={true}
                        controls={true}
                        config={playerConfig}
                        width=""
                        height="400px"
                        />
                        :
                         (
                            isAttachment && attachment != null
                                ? <PDFViewer attachment={url} />
                                : <></>
                        )
                }
                <div className='flex flex-col gap-3'>
                    <h2 className='text-2xl font-medium'>{title}</h2>
                    {/* <small className='font-medium bg-gray-200 w-fit py-1 px-2 rounded-xl'>{moment(publishedAt).format('MMMM D YYYY, h:mm A')}</small> */}
                </div>
                <ul className="flex items-center gap-2 text-sm font-medium text-center text-black border-b border-gray-200 mt-4">
                    <li onClick={() => setCurrentTab('description')} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'description' ? 'bg-blue-100' : ''}`}>
                        Description
                    </li>
                    {
                        isAssignment
                            ?
                            <li onClick={() => setCurrentTab('assignment')} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'assignment' ? 'bg-blue-100' : ''}`}>
                                Assignment
                            </li>
                            :
                            <></>
                    }

                    {
                        lesson_quiz ? 
                        <>
                        <button onClick={() => { setCurrentTab('quiz'); postResult(); handleShowModal()}} className={`inline-block px-4 py-3 rounded-t-lg cursor-pointer hover:bg-gray-200 ${currentTab === 'quiz' ? 'bg-blue-100' : ''}`}>Quiz</button>
                        </>
                         :
                        <></>
                    }
                </ul>
                {
                    currentTab === 'description' && (
                        <div>
                        <form action="" onSubmit={handleSubmit}>
                            <label htmlFor="comment"></label>
                            <input type="text" className="py-2 w-full rounded-lg border outline-none px-2" value={comment} onChange={handleChange} />
                            <button type="submit" className='bg-orange-500 py-2 px-3 text-white mt-2 mb-2 rounded-md'>submit</button>
                        </form>
                    </div>
                    )
                }
                {
                    currentTab === 'assignment' && isAssignment
                        ?
                        <div className='prose'
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(assignmentDescription) }}
                        />
                        :
                        <></>
                }
                {
                    currentTab === 'quiz'
                        ?
                        <QuizModal showModal={true} quizResultId={quizResult} handleShowModal={handleShowModal} lesson_quiz={lesson_quiz}/>
                        :
                        <></>
                }
            </div>


            
{
    totalReview && 
    <div>
        <h1>Comments {totalReview}</h1>
    <hr/>
    </div>
}
            {

                lesson_review?.map(comments => <LessonComment key={comments?.id} comments={comments} lessonId={id} />)
            }
        </>;
    }

    return lessonContent;
}

export default Lesson