import React from 'react';
import underDevelopment from '../../assets/images/underdevelopment.webp'

const CertificatePage = () => {
    return (
        <div>
            <img src={underDevelopment} className='w-full' alt="" />
        </div>
    );
};

export default CertificatePage;