import React from 'react';
import {useParams} from 'react-router-dom';
import SEO from '../../components/ui/SEO';
import {useGetSingleBlogsQuery} from '../../features/blogs/blogsAPI';

const SingleBlog = () => {

    const {slug} = useParams();
    const {data, isLoading, isError} = useGetSingleBlogsQuery(slug);
    const {data: blogSingle} = data || {};
    const {data: blog} = blogSingle || {};


    let blogsItem = null;

    if (isLoading) {

        blogsItem = <div className='text-center text-blue-700 text-lg p-4 font-medium'>loading.....</div>;
    } else if (!isLoading && isError) {
        blogsItem = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && blog?.length === 0) {
        blogsItem = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && blog?.length > 0) {
        blogsItem = blog.map(blog => (
            <>
                <SEO
                    title={`Blogs - ${blog?.title}`}
                    url={`/blogs/${blog?.slug}`}
                />
                <div>
                    <div className="max-w-screen-xl mx-auto">
                        <main className="mt-10">

                            <div className="mb-4 md:mb-0 w-full max-w-screen-md mx-auto relative"
                                 style={{height: '24em'}}>
                                <div
                                    className="absolute left-0 bottom-0 w-full h-full z-10 bg-gradient-to-t from-black"></div>
                                <img src={process.env.REACT_APP_BASE_URL + '/' + blog.thumbnail_image}
                                     className="absolute left-0 top-0 w-full h-full z-0 object-cover" alt='img'/>
                                <div className="p-4 absolute bottom-0 left-0 z-20">
                                    <a href="/"
                                       className="px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2">{blog.category.categoryName}</a>
                                    <h2 className="text-4xl font-semibold text-gray-100 leading-tight">
                                        {blog.title}
                                    </h2>
                                    <div className="flex mt-3">
                                        <img src={process.env.REACT_APP_BASE_URL + '/' + blog.thumbnail_image}
                                             className="h-10 w-10 rounded-full mr-2 object-cover" alt='img_author'/>
                                        <div>
                                            <p className="font-semibold text-gray-200 text-sm"> {blog.created.username} </p>
                                            <p className="font-semibold text-gray-400 text-xs">{blog.createdBlog}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="px-4 lg:px-0 mt-12 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed">
                                <p className="pb-6" dangerouslySetInnerHTML={{__html: blog.description}}/>

                            </div>
                        </main>

                    </div>

                </div>
            </>
        ))


    }


    return (
        blogsItem
    );
};

export default SingleBlog;