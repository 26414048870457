import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import AlertMessage from '../../../components/ui/AlertMessage'
import ForgotPasswordBackground from '../../../assets/images/coursebanner.jpg';
import {useForgotPasswordMutation} from '../../../features/auth/authAPI'
import SEO from '../../../components/ui/SEO';

const ForgotPassword = () => {

    const [phone, setPhone] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');


    const [forgotPassword, {data, isLoading, error}] = useForgotPasswordMutation();


    useEffect(() => {

        if (error?.data?.message) {
            setAlertMessage(error?.data?.message);
            setAlertMessageType('error');

        } else if (data?.status === 1) {

            setAlertMessage(data?.message);
            setAlertMessageType('success');
            setPhone('');
            window.location.href = `/account/verify-otp-forgot/${phone}`;


        } else if (data?.status === 0) {
            setAlertMessage(data?.message);
            setAlertMessageType('error');
        }

        setTimeout(() => setAlertMessage(''), 5000);

    }, [data, error, setAlertMessage, setAlertMessageType]);


    const handleSubmit = (e) => {
        e.preventDefault();

        setAlertMessage('');
        setAlertMessageType('');

        forgotPassword({phone});
    }


    return (
        <>
            <SEO
                title="Forgot Password"
                url="/forgot-password"
            />
            <div style={{backgroundImage: `url(${ForgotPasswordBackground})`, backgroundSize: 'cover'}}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <h1 className="text-3xl font-semibold text-center text-[#192b3d]">
                            Forgot Password
                        </h1>
                        {
                            alertMessage !== '' &&
                            <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage}/>
                            </div>
                        }
                        <form onSubmit={handleSubmit} className="mt-6">
                            <div>
                                <label
                                    htmlFor="phone"
                                    className="block text-sm font-semibold text-gray-800"
                                >
                                    Phone Number <sup className='text-red-500'>*</sup>
                                </label>
                                <input
                                    type="number"
                                    className={`${error?.data?.errors?.phone ? 'border-red-500' : ''}block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40`}
                                    id='phone'
                                    name='phone'
                                    value={phone || ''}
                                    onChange={e => setPhone(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-6">
                                {
                                    isLoading
                                        ?
                                        <button
                                            type="button"
                                            className="w-full flex items-center justify-center gap-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                            disabled
                                        >
                                            <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span> Submitting ... </span>
                                        </button>
                                        :
                                        <button
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                        >
                                            Submit
                                        </button>
                                }
                            </div>
                        </form>

                        <p className="mt-8 text-xs font-light text-center text-gray-700">
                            <Link
                                to="/login"
                                className="font-medium text-[#192b3d] hover:underline"
                            >
                                Back to login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword