import React from 'react'
import { Link } from 'react-router-dom';
import DefaultImage from '../../../assets/images/technical-support.png';

const LandingCategoryItem = ({category}) => {

    const { name, slug, icon } = category || {};

    const categoryIcon = process.env.REACT_APP_BASE_URL + '/' + icon;

    return (
        <div className='w-[90%]'>
            <a href={`/${slug}/courses`} className='p-4 text-center w-full'>
                <div
                    className="relative p-4 h-40 bg-white rounded-lg overflow-hidden hover:shadow flex flex-col justify-center items-center"
                >
                    <div className="w-16 h-16 bg-gray-100 rounded-lg">
                        <img src={icon !== null ? categoryIcon : DefaultImage} alt="category" />
                    </div>
                    <h2 className="mt-2 text-[#292d60] hover:text-gray-800 text-sm font-semibold line-clamp-2">
                        <a href={`/${slug}/courses`}>{name} </a>
                    </h2>
                </div>
            </a>
        </div>
    )
}

export default LandingCategoryItem