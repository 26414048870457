import React, {useState} from 'react';
import {useGetBlogsQuery} from '../../features/blogs/blogsAPI';
import BlogItem from './BlogItem';
// import Pagination from 'react-js-pagination';
import './blog.css';

import SEO from '../../components/ui/SEO';
import CourseSkeleton from '../../components/ui/Skeleton/CourseSkeleton';
import Pagination from '../../components/ui/Pagination';

const Blog = () => {
    const [page, setPage] = useState(1);
    // for custom pagination 
    // const [currentPage, setCurrentPage] = useState(1);
    // const [postsPerPage] = useState(3);

    const {data, isLoading, isError} = useGetBlogsQuery(page);
    const {data: blogsAll} = data || {};
    const {data: blogs, meta} = blogsAll || {};


    const {current_page, per_page, total, last_page} = meta || {};

    const perPageItems = per_page;
    const totalItem = total;
    const currentPage = current_page;
    const lastPage = last_page;
    const totalPage = lastPage;


    const handlePageChange = () => {
        setPage((prev) => prev + 1);
    }

    function previousClick() {
        setPage(page - 1)
    }

    const handlePaginate = (page) => {
        setPage(page)
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }
    // console.log(pageNumbers);


    let blogsItems = null;

    if (isLoading) {

        blogsItems = <>

            <div className='grid grid-cols-4 gap-10'><CourseSkeleton/></div>
            <div className='grid grid-cols-4 gap-10'><CourseSkeleton/></div>
            <div className='grid grid-cols-4 gap-10'><CourseSkeleton/></div>


        </>;
    } else if (!isLoading && isError) {
        blogsItems = <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && blogs?.length === 0) {
        blogsItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && blogs?.length > 0) {
        blogsItems = blogs.map(blog => (
            <BlogItem key={blog.id} blog={blog}
            />
        ))
    }

    return (
        <>
            <SEO
                title="Blog"
                url="/blogs"
            />
            <div className='mt-10 container m-auto max-w-[1260px]'>
                <div className='text-center mb-10'>
                    <h1 className='text-3xl font-bold '>Letest From Blog</h1>
                    <p className='text-gray-400 w-[60%] m-auto mt-3'></p>
                </div>


                <div
                    className="p-10 lg:grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7">
                    {
                        blogsItems
                    }
                </div>

                <div>
                    {
                        blogs?.length > 0 &&
                        (
                            perPageItems < totalItem &&
                            <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem}
                                        handlePaginate={handlePaginate}/>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default Blog;