import React, {useEffect, useState} from 'react';
import PieChart from './PieChart';
import {useCookies} from 'react-cookie';

const QuizResult = () => {

    const [resutldata, setResultData] = useState([]);
    const quizTestId = localStorage.getItem("QuizTestId");
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const token = auth?.accessToken;

    useEffect(() => {
    fetch(`${baseUrl}/api/v1/exam/quiz-test/${quizTestId ?? ''}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
    })
        .then(response => response.json())
        .then(data => {
            setResultData(data?.data);
        })
        .catch(error => {
            console.error(error);
        });
    }, []);


    return (
        <>
            <div className='mt-7 px-5'>
                <h1 className='text-2xl text-center bg-[#1476A5] text-white font-semibold rounded-lg py-2 uppercase mb-10'>Result</h1>

                <div className="flex justify-evenly items-center">
                    <div>
                    {
                                resutldata?.map(result => (
                                    <PieChart key={result.id} result={result}/>
                                ))
                            }
                        
                    </div>
                    <div className='flex items-center justify-between'>
                        <div className=''>
                            <h4 className='py-2 text-lg font-semibold'>Total Question:</h4>
                            <h4 className='py-2 text-lg font-semibold'>Total Mark :</h4>
                            <h4 className='py-2 text-lg font-semibold'>Correct Answer :</h4>
                            <h4 className='py-2 text-lg font-semibold'>Wrong Answer :</h4>

                        </div>
                        <div>
                            {
                                resutldata?.map(resultdata => (
                                  <>
                                  <h4 className='py-2 text-lg font-semibold'>{(parseFloat(resultdata?.total_quiz))}</h4>
                                  <h4 className='py-2 text-lg font-semibold'>{(parseFloat(resultdata?.total_marks))}</h4>
                                  <h4 className='py-2 text-lg font-semibold'>{resultdata?.quiz_test_quizzes?.length}</h4>
                                  <h4 className='py-2 text-lg font-semibold'>{parseFloat(resultdata?.total_quiz) - resultdata?.quiz_test_quizzes?.length}</h4>
                                  </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuizResult;