import React from 'react';
import Slider from 'react-slick';
import {FaAngleLeft, FaAngleRight, FaArrowRight} from 'react-icons/fa';
import LandingLatestCourseItem from './LandingLatestCourseItem';
import {useGetLatestCoursesQuery} from '../../../features/courses/coursesAPI';
import CourseSkeleton from '../../ui/Skeleton/CourseSkeleton';
import LetestCourseSkeleton from '../../ui/Skeleton/Course/LetestCourseSkeleton';


const LandingLatestCourse = () => {

    const sliderSettings = {
        infinite: true,
        autoplay: true,
        speed: 500,
        dots: true,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        nextArrow: <FaAngleRight/>,
        prevArrow: <FaAngleLeft/>,
        appendDots: dots => <ul>{dots}</ul>,
        customPaging: i => (
            <div className="ft-slick__dots__hottype ">
                <div className="loading"/>
            </div>
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    nextArrow: <FaAngleRight/>,
                    prevArrow: <FaAngleLeft/>,

                }
            },
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    nextArrow: <FaAngleRight/>,
                    prevArrow: <FaAngleLeft/>,

                }
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 2,
                    arrows: true,
                    nextArrow: <FaAngleRight/>,
                    prevArrow: <FaAngleLeft/>,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    nextArrow: <FaAngleRight/>,
                    prevArrow: <FaAngleLeft/>,

                }
            },

        ]
    };


    const {data, isLoading, isError} = useGetLatestCoursesQuery();
    const {data: responseData} = data || {};
    const {data: latestCourses} = responseData || {};

    let latestCoursesItems = null;

    if (isLoading) {
        latestCoursesItems = <>
            <div className='flex flex-row justify-center mx-auto items-center gap-52'>
                <div className='grid grid-cols-4 gap-10'><LetestCourseSkeleton/></div>
                <div className='grid grid-cols-4 gap-10'><LetestCourseSkeleton/></div>
                <div className='grid grid-cols-4 gap-10'><LetestCourseSkeleton/></div>
                <div className='grid grid-cols-4 gap-10'><LetestCourseSkeleton/></div>
            </div>
        </>
        sliderSettings.infinite = false
    } else if (!isLoading && isError) {
        latestCoursesItems =
            <div className='text-center text-yellow-100 text-lg p-4 font-medium'>Something wrong!</div>;
    } else if (!isLoading && !isError && latestCourses?.length === 0) {
        latestCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if (!isLoading && !isError && latestCourses?.length > 0) {
        latestCoursesItems = latestCourses.map(latestCourse => <LandingLatestCourseItem key={latestCourse.id}
                                                                                        latestCourse={latestCourse}/>);

        if (
            (latestCourses.length <= 4 && sliderSettings.responsive[0].breakpoint >= 1536) ||
            (latestCourses.length <= 4 && sliderSettings.responsive[0].breakpoint >= 1024) ||
            (latestCourses.length <= 3 && sliderSettings.responsive[1].breakpoint === 768) ||
            (latestCourses.length <= 2 && sliderSettings.responsive[2].breakpoint === 680)
        ) {
            sliderSettings.infinite = false
        }
    }


    return (
        <div className='pb-7 mx-auto max-w-[1265px]'>
            <div className='text-[#292d60]'>
                <h1 className='pt-10 border-0 ml-5 uppercase font-semibold mb-5'>
                    Latest Courses
                    <a className='float-right mr-5 font-normal normal-case' href="/latest-courses">
                        view all <FaArrowRight className='inline h-3 mb-1'/>
                    </a>
                </h1>
            </div>
            <Slider className='mx-5' {...sliderSettings}>
                {
                    latestCoursesItems
                }
            </Slider>
        </div>
    );
};

export default LandingLatestCourse;