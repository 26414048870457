import React from 'react'
import {Route, Routes} from 'react-router-dom'
import Login from '../pages/auth/Login'
import Register from '../pages/auth/Register'
import Verify from '../pages/auth/verify/Verify'
import CourseDetails from '../pages/CourseDetails/CourseDetails'
import Instructors from '../pages/InstructorPage/Instructors'
import VerifySuccess from '../pages/auth/verify/VerifySuccess';
import VerifyFailed from '../pages/auth/verify/VerifyFailed';
import ForgotPassword from '../pages/auth/forgotPassword/ForgotPassword';
import ResetPassword from '../pages/auth/forgotPassword/ResetPassword';
import PublicRouteProvider from '../components/route/PublicRouteProvider'
import Home from '../pages/Home/Home'
import SubscriberVerify from '../pages/subscriber/verify/SubscriberVerify';
import SubscriberVerifySuccess from '../pages/subscriber/verify/SubscriberVerifySuccess';
import SubscriberVerifyFailed from '../pages/subscriber/verify/SubscriberVerifyFailed';
import Course from '../pages/Course/Course'
import CategoryPage from '../pages/categoryPage/CategoryPage'
import NotFound from '../components/notFound/NotFound'
import Invoice from '../pages/account/enrollment/Invoice'
import PlayVideo from '../pages/PlayVideo/PlayVideo';
import CreateInstructor from '../pages/Instructor/CreateInstructor'
import Blog from '../pages/Blog/Blog'
import Faqs from '../pages/Faqs/Faqs'
import About from '../components/about/About'
import ContactUs from '../pages/ContactUs/ContactUs'
import Support from '../pages/Support/Support'
import SingleBlog from '../pages/Blog/SingleBlog'
import CoursePage from '../pages/CoursePage/CoursePage'
import Exams from '../pages/Exam/Exams'
import ExamDetails from '../pages/Exam/ExamDetails'
import ChapterDetails from '../pages/ChapterDetails/ChapterDetails'
import QuizResult from '../pages/ChapterDetails/QuizResult'
import TermsAndCondition from '../pages/TermsAndCondition/TermsAndCondition'
import ReturnAndRefundPolicy from '../pages/ReturnAndRefundPolicy/ReturnAndRefundPolicy'
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy'
import InstructorList from '../pages/InstructorPage/InstructorList'
import Testimonials from '../components/Testimonial/Testimonials'
import PopularCourseAll from '../components/Home/Course/Popular/PopularCourseAll'
import TagCourseAll from '../components/Home/Course/Tag/TagCourseAll'
import PaymentFailed from '../components/PaymentFailed/PaymentFailed'
import VerifyOtp from '../pages/auth/verify/VerifyOtp'
import VerifyOtpForgot from '../pages/auth/forgotPassword/VerifyOtpForgot'
import LatestCourseAll from '../components/Home/Course/Latest/LatestCourseAll'
import VerifySuccessForgot from '../pages/auth/forgotPassword/VerifySuccess'
import LandingPage from "../components/Landing/LandingPage";
// import YoutubeVideos from '../pages/youtubeVideos/YoutubeVideos'

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<LandingPage/>}/>
            <Route path='/play-video' element={<PlayVideo/>}/>
            <Route path='/:categorySlug/courses' element={<Course/>}/>
            <Route path='/courses/:slug' element={<CourseDetails/>}/>
            <Route path='/instructors/:userName' element={<Instructors/>}/>
            <Route path='/instructors' element={<InstructorList/>}/>
            <Route path='/category-page' element={<CategoryPage/>}/>
            <Route path='/invoice' element={<Invoice/>}/>
            <Route path='/instructor-registration' element={<CreateInstructor/>}/>
            <Route path='/blogs' element={<Blog/>}/>
            <Route path='/blogs/:slug' element={<SingleBlog/>}/>
            <Route path='/faqs' element={<Faqs/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact-us' element={<ContactUs/>}/>
            <Route path='/support' element={<Support/>}/>
            <Route path='/course-page' element={<CoursePage/>}/>
            <Route path='/exams' element={<Exams/>}/>
            <Route path='/exams/:slug' element={<ExamDetails/>}/>
            <Route path='/exams/:slug/chapters/:chapterSlug' element={<ChapterDetails/>}/>
            <Route path='/quiz-result' element={<QuizResult/>}/>
            <Route path='/terms-and-condition' element={<TermsAndCondition/>}/>
            <Route path='/return-and-refund-policy' element={<ReturnAndRefundPolicy/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/testimonials' element={<Testimonials/>}/>
            <Route path='/popular-courses' element={<PopularCourseAll/>}/>
            <Route path='/latest-courses' element={<LatestCourseAll/>}/>
            <Route path='/:tagId-courses' element={<TagCourseAll/>}/>
            <Route path='/payment-failed' element={<PaymentFailed/>}/>
            <Route path='/professional' element={<Home/>}/>


            <Route path='/login' element={
                <PublicRouteProvider>
                    <Login/>
                </PublicRouteProvider>
            }/>
            <Route path='/register' element={
                <PublicRouteProvider>
                    <Register/>
                </PublicRouteProvider>
            }/>

            <Route path='/account/verify/:verifyToken/:hashEmail' element={<Verify/>}/>
            <Route path='account/verify-otp/:phone' element={<VerifyOtp/>}/>
            <Route path='/account/verify-otp-forgot/:phone' element={<VerifyOtpForgot/>}/>
            <Route path="/account/verify/success" element={<VerifySuccess/>}/>
            <Route path="/account/verify-forgot/success" element={<VerifySuccessForgot/>}/>
            <Route path="/account/verify/failed" element={<VerifyFailed/>}/>
            <Route path="/account/forgot-password/" element={<ForgotPassword/>}/>
            <Route path="/account/reset-password/:phone" element={<ResetPassword/>}/>

            <Route path='/subscriber/verify/:verifyToken/:hashEmail' element={<SubscriberVerify/>}/>
            <Route path="/subscriber/verify/success" element={<SubscriberVerifySuccess/>}/>
            <Route path="/subscriber/verify/failed" element={<SubscriberVerifyFailed/>}/>

            {/* <Route path="/youtube-videos" element={<YoutubeVideos />} /> */}

            <Route path='/*' element={<NotFound/>}/>
        </Routes>
    )
}

export default PublicRoutes