import React, {useEffect, useState} from 'react'
import {VscEye, VscEyeClosed} from 'react-icons/vsc';
import {Link, useNavigate, useParams} from 'react-router-dom'
import AlertMessage from '../../../components/ui/AlertMessage';
import {useResetPasswordMutation} from '../../../features/auth/authAPI';
import ResetPasswordBackground from '../../../assets/images/coursebanner.jpg';
import SEO from '../../../components/ui/SEO';

const ResetPassword = () => {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const {phone} = useParams();


    const togglePassword = (type) => {
        if (type === 'password') {
            setPasswordShown(!passwordShown);
        } else {
            setConfirmPasswordShown(!confirmPasswordShown);
        }
    };


    const [resetPassword, {data, isLoading, error}] = useResetPasswordMutation();


    const navigate = useNavigate();


    useEffect(() => {
        if (error?.data?.errors?.password) {

            setAlertMessage(error?.data?.errors?.password);
            setAlertMessageType('error');

        } else if (data?.status === 1) {

            setAlertMessage(data?.message);
            setAlertMessageType('success');
            setPassword('');
            setConfirmPassword('');
            window.location.href = `/login`;

        } else if (data?.status === 0) {
            setAlertMessage(data?.message);
            setAlertMessageType('error');
        }

        setTimeout(() => setAlertMessage(''), 5000);

    }, [data, error, navigate]);


    const handleSubmit = (e) => {
        e.preventDefault();

        setAlertMessage('');
        setAlertMessageType('');

        if (password !== confirmPassword) {
            setAlertMessage('Passwords do not matched!');
            setAlertMessageType('error');
        } else {
            // console.log({phone, password, confirmPassword,});
            resetPassword({
                phone,
                password,
                password_confirmation: confirmPassword,
            })
        }
    }


    return (
        <>
            <SEO
                title="Reset Password"
            />
            <div style={{backgroundImage: `url(${ResetPasswordBackground})`, backgroundSize: 'cover'}}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <h1 className="text-3xl font-semibold text-center text-[#192b3d]">
                            Reset Password
                        </h1>
                        {
                            alertMessage !== '' &&
                            <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage}/>
                            </div>
                        }
                        <form onSubmit={handleSubmit} className="mt-6">
                            <div className='relative mb-5'>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-semibold text-gray-800"
                                >
                                    New Password <sup className='text-red-500'>*</sup>
                                </label>
                                <input
                                    type={passwordShown ? "text" : "password"}
                                    className="relative block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40"
                                    id='password'
                                    name='password'
                                    value={password || ''}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                                {
                                    passwordShown
                                        ? <VscEyeClosed onClick={() => togglePassword('password')}
                                                        className="absolute right-3 top-10"/>
                                        : <VscEye onClick={() => togglePassword('password')}
                                                  className="absolute right-3 top-10"/>
                                }
                            </div>
                            <div className='relative mb-5'>
                                <label
                                    htmlFor="confirmPassword"
                                    className="block text-sm font-semibold text-gray-800"
                                >
                                    Confirm Password <sup className='text-red-500'>*</sup>
                                </label>
                                <input
                                    type={confirmPasswordShown ? "text" : "password"}
                                    className="relative block w-full px-4 py-2 mt-2 text-[#192b3d] bg-white border rounded-md focus:border-[#192b3d] focus:ring-[#192b3d] focus:outline-none focus:ring focus:ring-opacity-40"
                                    id='confirmPassword'
                                    name='confirmPassword'
                                    value={confirmPassword || ''}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    required
                                />
                                {
                                    confirmPasswordShown
                                        ? <VscEyeClosed onClick={() => togglePassword('confirmPassword')}
                                                        className="absolute right-3 top-10"/>
                                        : <VscEye onClick={() => togglePassword('confirmPassword')}
                                                  className="absolute right-3 top-10"/>
                                }
                            </div>
                            <div className="mt-6">
                                {
                                    isLoading
                                        ?
                                        <button
                                            type="button"
                                            className="w-full flex items-center justify-center gap-2 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                            disabled
                                        >
                                            <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg"
                                                 fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <span> Submitting ... </span>
                                        </button>
                                        :
                                        <button
                                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#192b3d] rounded-md hover:bg-[#192b3d] focus:outline-none focus:bg-[#192b3d]"
                                        >
                                            Submit
                                        </button>
                                }
                            </div>
                        </form>

                        <p className="mt-8 text-xs font-light text-center text-gray-700">
                            <Link
                                to="/login"
                                className="font-medium text-[#192b3d] hover:underline"
                            >
                                Back to login
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;