import React from 'react';
import play from '../../assets/images/google-play-icon.png';
import apple from '../../assets/images/ios-store-icon.png';
import Logo from '../../assets/images/logo.png';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useGetSocialLinksQuery} from '../../features/socialLinks/socialLinksAPI';
import paymentImage from '../../assets/images/payment.png';

const Footer = () => {


    const {siteName, logo, address, phone, email, shortDescription} = useSelector(state => state.siteInfo);
    const siteLogo = process.env.REACT_APP_BASE_URL + '/' + logo;


    const {data, isLoading, isError} = useGetSocialLinksQuery();
    const {data: socialLinks} = data || {};

    let socialLinkLists = null;

    if (isLoading) {
        socialLinkLists = <>
            <p className='animate-pulse h-4 w-4 rounded-full bg-slate-200'></p>
            <p className='animate-pulse h-4 w-4 rounded-full bg-slate-200'></p>
            <p className='animate-pulse h-4 w-4 rounded-full bg-slate-200'></p>
            <p className='animate-pulse h-4 w-4 rounded-full bg-slate-200'></p>
            <p className='animate-pulse h-4 w-4 rounded-full bg-slate-200'></p>
        </>
    } else if (!isLoading && !isError && socialLinks?.length > 0) {
        socialLinkLists = socialLinks.map(socialLink => {
            const {
                id,
                url,
                icon,
                background_color: backgroundColor,
                foreground_color: foregroundColor
            } = socialLink || {};

            return (
                <li key={id} style={{color: `${foregroundColor}`}}>
                    <a href={url} target="_blank">
                        <ion-icon className='h-7 w-7' style={{height: '30px', width: '30px'}} name={icon}></ion-icon>
                    </a>
                </li>
            )
        })
    }


    return (
        <div className=' text-[#192b3d] pt-10 mt-10 bg-white border-t'>
            <div className='mx-auto max-w-[1265px] '>
                <div className='grid lg:grid-cols-6'>
                    <div className='lg:col-span-2 m-auto  lg:text-left'>
                        <img className='w-[94px] h-[34px] m-auto lg:m-[initial]'
                             src={siteLogo !== null ? siteLogo : Logo} alt="logo"/>
                        <h4 className='text-xl font-bold mt-3'>{siteName}</h4>
                        <p className=''>{shortDescription}</p>
                        <p className='mt-3 font-semibold'>Download our app </p>
                        <div className='flex space-x-3 mt-3 justify-center lg:justify-start'>
                            <img src={play} alt="play"/>
                            <img src={apple} alt="apple"/>
                        </div>
                    </div>
                    <div className='m-auto mt-4 lg:mt-0  lg:text-left'>
                        <h1 className='font-bold mb-3'>Important Link</h1>
                        <ul>
                            <li className='mb-2'><Link to="/instructors">Instructor</Link></li>
                            <li className='mb-2'><Link to="/course-page">Course</Link></li>
                            <li className='mb-2'><Link to="/terms-and-condition">Terms & Conditions</Link></li>
                            <li className='mb-2'><Link to="/return-and-refund-policy">Return & Refund Policy</Link></li>
                            <li className='mb-2'><Link to="/privacy-policy">Privacy Policy</Link></li>

                            <li className='mb-2'><Link to="/testimonials">Testimonials</Link></li>
                        </ul>
                    </div>
                    <div className='m-auto mt-4 lg:mt-0  lg:text-left'>
                        <h1 className='font-bold mb-3'>Others</h1>
                        <ul>
                            <li className='mb-2'><Link to="/blogs">Blogs</Link></li>
                            {/* <li className='mb-2'><a href="/">Become Student </a></li> */}
                            <li className='mb-2'><Link to="/instructor-registration">Become Instructor</Link></li>
                            <li className='mb-2'><Link to="/support">Support</Link></li>
                            <li className='mb-2'><Link to="/about">About</Link></li>
                            <li className='mb-2'><Link to="/contact-us">Contact Us</Link></li>
                            <li className='mb-2'><Link to="/faqs">FAQ</Link></li>
                        </ul>
                    </div>
                    <div className='lg:col-span-2 m-auto mt-4 lg:mt-0  lg:text-left'>
                        <h1 className='font-bold mb-3'>Connect Us</h1>
                        <div>
                            <p className='mb-2 font-semibold'>Address: <span className='font-normal'>{address}</span>
                            </p>
                            <p className='mb-2 font-semibold'>Phone: <a className='font-normal'
                                                                        href={`tel:${phone}`}>(+88)-{phone}</a></p>
                            <p className='mb-2 font-semibold'>Email: <a className='font-normal'
                                                                        href={`mailto:${email}`}>{email}</a></p>
                            <ul className='flex space-x-5 mt-4 justify-center lg:justify-start'>
                                {
                                    socialLinkLists
                                }
                            </ul>

                        </div>

                    </div>
                </div>

                <img src={paymentImage} alt=""/>
                <div
                    className=' mb-16 mt-5 text-gray-500 lg:mb-2 lg:flex  lg:justify-between border-t pt-2 pb-2 mx-3'>
                    <p>Copyright &copy; <Link to='/'>{siteName}</Link></p>
                    <p>Developed by: <a href="https://www.bizzsol.com.bd" target="_blank">Bizz Solutions PLC</a>
                    </p>
                </div>

            </div>

        </div>
    );
};

export default Footer;