import React, {useState} from 'react';
import {useCookies} from 'react-cookie';
import {useGetQuizTestQuizzesQuery} from '../../features/exams/examsAPI';
import {useNavigate} from 'react-router-dom';
import QuizSkeleton from '../../components/ui/Skeleton/QuizSkeleton';

const ChaptarQuiz = ({quiz,index,page,createQuizData,error,createExamQuizTest,user,handlePageChange, currentPage,setPage,
                         lastPage,handleProgressClick}) => {

    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOptionIsTrue, setSelectedOptionIsTrue] = useState(false);
    const navigate = useNavigate();
    const [cookies] = useCookies(['EduTvAuth']);
    const auth = cookies?.EduTvAuth;
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = auth?.accessToken;

    // set page for quiztest quizzes
    const [quizPage, setQuizPage] = useState(1);
    const quizTestId = localStorage.getItem("QuizTestId");

    const { data, isLoading, isError } = useGetQuizTestQuizzesQuery({ page, quizTestId });
    const { data: QuizTestQuizzes } = data || {};
    const { data: quizez } = QuizTestQuizzes || {};

    let quizzesId = '';
    let quizMark = quiz?.marks;
    if (!isLoading && !isError && quizez?.length > 0) {
        quizzesId = quizez[0]?.id;
    }

    if (selectedOptionIsTrue === 1) {
       
    }

    const handleQuizPageChange = () => {
        setQuizPage((prev) => prev + 1);
    }

    const handleOptionChange = (event) => {
        const selectedOptionId = event.target.value;
        const selectedOption = quiz?.quizOptions.find(
            (option) => option.id === parseInt(selectedOptionId)
        );
        setSelectedOption(selectedOptionId);
        setSelectedOptionIsTrue(selectedOption?.is_true);
    };

    function handleQuizClick(event) {
        event.preventDefault();
        if(currentPage !== lastPage){
            handlePageChange();
            handleQuizPageChange();
        }

        if(currentPage === lastPage){
           
                fetch(`${baseUrl}/api/v1/exam/exam-quiz-test-quizzes/${quizTestId ?? ''}/${quizzesId?? ''}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        totalMarks: quizMark,
                        is_completed: 1,
                        mark: quizMark,
                    })
                })
                    .then(response => response.json())
                    .then(result => {
    
                        // console.log(result);
                        navigate('/quiz-result')
                    })
                    .catch(error => {
                        console.error(error);
                    });
            
            
        }else{
            if (selectedOptionIsTrue === 1) {
                fetch(`${baseUrl}/api/v1/exam/exam-quiz-test-quizzes/${quizTestId ?? ''}/${quizzesId ?? '' }`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        totalMarks: quizMark,
                        is_completed: 0,
                        // quizId: quiz?.id,
                        mark: quizMark,
                    })
                })
                    .then(response => response.json())
                    .then(result => {
    
                        // console.log(result);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }  
        }
        

        setSelectedOption("")
    }


    function previousClick() {
        setPage(page - 1)
        setQuizPage(quizPage - 1)
    }

    return (
        <>
            <div className='p-3'>
                <h2 className='font-bold text-2xl'>Question {page}/{lastPage}:</h2>
                <h3 className='p-4 text-xl font-semibold'>{quiz.title}</h3>
            </div>
            <ul className='grid gap-6'>
              

                {
                    isLoading ? <>
                    <QuizSkeleton/>
                    </> :
                    quiz?.quizOptions.map((option) => (
                        <label key={option.id} className={`inline-flex items-center w-full py-3 hover:bg-[#1476a5] hover:text-white rounded-md px-4 duration-300 ${selectedOption === option.id.toString() ? 'bg-[#1476a5] text-white': '' } `}>
                            <input
                            
                                type="radio"
                                value={option.id || ''}
                                checked={selectedOption === option.id.toString()}
                                onChange={handleOptionChange}
                                className="form-radio h-5 w-5"
                            />
                            <span className="ml-2 ">{option.name}</span>
                        </label>
                    ))
                }

            </ul>
            <div className='mt-5 flex justify-between'>
                {
                    currentPage === 1 ?
                        <button className='bg-blue-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6' disabled>Previous</button>
                        :
                        <button onClick={previousClick} className='bg-blue-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6' >Previous</button>
                }

                {
                    currentPage === lastPage ?
                        <button onClick={handleQuizClick} className='bg-green-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6'>Submit</button>
                        :
                        <>
                            <button onClick={handleQuizClick} className='bg-green-800 text-white rounded-md pt-2 pb-2 pr-6 pl-6'>Save and Next</button>
                        </>
                }S
            </div>
        </>
    );
};

export default ChaptarQuiz;