import React from 'react';
import LandingCategoryItem from "../Landing/componant/LandingCategoryItem";
import {
    useGetCourseCategoryDetailsQuery
} from "../../features/courseCategories/courseCategoriesAPI";
import {FaArrowRight} from "react-icons/fa";

function ChildCategories({slug}) {
    const {data, isLoading, isError} = useGetCourseCategoryDetailsQuery(slug);
    const {data: responseData} = data || {};

    return (
        <>
            <div className="row container">
                <div className='text-[#292d60]'>
                    <h1 className='pt-5 border-0 uppercase font-semibold mb-2'>
                        {!isLoading && responseData?.name}
                    </h1>
                </div>
                {!isLoading && responseData?.child_course_categories?.length > 0 && responseData?.child_course_categories.slice(0, 12).map((category) => (
                    <div key={category.id} className="col-lg-3 col-md-4 col-sm-6 col-xs-6 mb-4">
                        <LandingCategoryItem category={category}/>
                    </div>
                ))}
            </div>
        </>
    );
}

export default ChildCategories;