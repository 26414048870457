import React, {useEffect} from 'react';
import {BsArrowRight} from "react-icons/bs";
import {HiCheckBadge} from "react-icons/hi2";
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import SEO from '../../../components/ui/SEO';

const VerifySuccessForgot = () => {
    const {accountVerifying} = useSelector(state => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (!accountVerifying) {
            navigate('/account/reset-password/${phone}');
        }
    }, [accountVerifying, navigate])

    return (
        <>
            <SEO
                title="Verify Success"
                description="Account verify has been successfully!"
            />
            <div style={{backgroundColor: '#6DB3F2'}}>
                <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
                    <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                        <div className='flex flex-col items-start gap-3'>
                            <div className='flex items-center justify-center gap-2 text-2xl md:text-3xl'>
                                <HiCheckBadge className='text-blue-600'/>
                                <span className="text-blue-600 font-semibold"> Verified Successfully </span>
                            </div>
                            <Link to='/login' className='flex items-center gap-1'>Go to Login <BsArrowRight/></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifySuccessForgot;