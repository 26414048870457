import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import AlertMessage from '../../components/ui/AlertMessage';
import SEO from '../../components/ui/SEO';
import {useCreateInstructorMutation} from '../../features/Instructor/InstructorAPI';


const CreateInstructor = () => {
    // const [inputList, setInputList] = useState([{ title: "", institute: "", session: "" }]);
    // const [skillinput, setSkillList] = useState([{ skills: "" }]);
    const [skills, setSkills] = useState([]);
    // const [educations, setEducations] = useState({});
    const [title, setTitle] = useState([]);
    const [institute, setInstitute] = useState([]);
    const [session, setSession] = useState([]);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [stateAddress, setStateAddress] = useState("");
    const [stateAddress2, setStateAddress2] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [image, setImage] = useState("");
    const [gender, setGender] = useState("");
    const [password, setPassword] = useState("");
    const [bio, setBio] = useState("");
    const [experience, setExperience] = useState("")
    const [alertMessage, setAlertMessage] = useState('');
    const [alertMessageType, setAlertMessageType] = useState('');


    const [createInstructor, {data, isLoading, error}] = useCreateInstructorMutation();

    useEffect(() => {

        if (error?.data?.errors?.first_name) {
            setAlertMessage(error?.data?.errors?.first_name);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.last_name) {
            setAlertMessage(error?.data?.errors?.last_name);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.username) {
            setAlertMessage(error?.data?.errors?.username);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.phone) {
            setAlertMessage(error?.data?.errors?.phone);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.email) {
            setAlertMessage(error?.data?.errors?.email);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.stateAddress) {
            setAlertMessage(error?.data?.errors?.stateAddress);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.stateAddress2) {
            setAlertMessage(error?.data?.errors?.stateAddress2);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.postalCode) {
            setAlertMessage(error?.data?.errors?.postalCode);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.image) {
            setAlertMessage(error?.data?.errors?.image);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.gender) {
            setAlertMessage(error?.data?.errors?.gender);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.password) {
            setAlertMessage(error?.data?.errors?.password);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.bio) {
            setAlertMessage(error?.data?.errors?.bio);
            setAlertMessageType('error');
        } else if (error?.data?.errors?.experience) {
            setAlertMessage(error?.data?.errors?.experience);
            setAlertMessageType('error');
        } else if (data?.status === 0) {
            setAlertMessage(data?.message);
            setAlertMessageType('error');
        } else if (data?.status === 1) {
            setAlertMessage(data?.message);
            setAlertMessageType('success');
            setFirstName("");
            setLastName("");
            setUsername("");
            setPhone("");
            setEmail("");
            setStateAddress("");
            setStateAddress2("");
            setPostalCode("");
            setImage("");
            setGender("");
            setPassword("");
            setBio("");
            setExperience("")


        }

        setTimeout(() => setAlertMessage(''), 5000);

    }, [data, error]);


    const handleSubmit = (e) => {
        e.preventDefault();
        setAlertMessage('');
        setAlertMessageType('');


        createInstructor({
            first_name: firstName,
            last_name: lastName,
            username: userName,
            email,
            phone: phone,
            address_1: stateAddress,
            address_2: stateAddress2,
            postcode: postalCode,
            image,
            gender: gender,
            password: password,
            bio,
            experience,
            skills,
            title


        })

    }


    // add skills
    const handleskillAdd = () => {
        setSkills([...skills, ""]);

    };

    // remove skills
    const handleskillRemove = (index) => {
        const newSkills = [...skills];
        newSkills.splice(index, 1);
        setSkills(newSkills);
    };

    // Change skills
    const handleSkillsChange = (value, index) => {
        const newSkills = [...skills];
        newSkills[index] = value;
        setSkills(newSkills);
    };

    // add educations
    const addEducations = () => {
        setTitle([...title, ""]);
        setInstitute([...institute, ""]);
        setSession([...session, ""]);
    };

    // remove educations
    const removeEducations = (index) => {
        const newTitle = [...title];
        const newInstitute = [...institute];
        const newSession = [...session];
        newTitle.splice(index, 1);
        newInstitute.splice(index, 1);
        newSession.splice(index, 1);
        setTitle(newTitle);
        setInstitute(newInstitute);
        setSession(newSession);
    };


    // change educations

    const handleTitleChange = (value, index) => {
        const newTitle = [...title];
        newTitle[index] = value;
        setTitle(newTitle);
    };

    const handleInstituteChange = (value, index) => {
        const newInstitute = [...institute];
        newInstitute[index] = value;
        setInstitute(newInstitute);
    };

    const handleSessionChange = (value, index) => {
        const newSession = [...session];
        newSession[index] = value;
        setSession(newSession);
    };


    return (
        <>
            <SEO
                title="Create Instructor"
                url="/instructor-registration"
            />
            <div className='px-10 mt-10'>
                {
                    alertMessage !== '' &&
                    <div className='w-full pt-5'><AlertMessage type={alertMessageType} message={alertMessage}/></div>
                }
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="mt-10 sm:mt-0 mb-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Basic Information</h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Use a Valid Information
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">

                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="first_name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    First name <sup className='text-red-500'>*</sup>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="first_name"
                                                    id="first_name"
                                                    autocomplete="given-name"
                                                    className="mt-1 focus:ring-indigo-500 outline-none focus:border-indigo-500 border p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"

                                                    value={firstName || ''}
                                                    onChange={e => setFirstName(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">

                                                <label
                                                    for="last_name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Last name <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="last_name"
                                                    id="last_name"
                                                    autocomplete="family-name"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={lastName || ''}
                                                    onChange={e => setLastName(e.target.value)}
                                                />

                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="username"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    UserName <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    autocomplete="family-name"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={userName || ''}
                                                    onChange={e => setUsername(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="phone"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Phone <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="phone"
                                                    id="phone"
                                                    autocomplete="family-name"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={phone || ''}
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    autocomplete="family-name"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={email || ''}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>

                                            {/*
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="country"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Country / Region
                                                </label>

                                                <select
                                                    id="country"
                                                    name="country"
                                                    autocomplete="country"
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                >
                                                    <option>United States</option>
                                                    <option>Canada</option>
                                                    <option>Mexico</option>
                                                </select>
                                            </div> */}

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="address_1"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Street address 1 <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="address_1"
                                                    id="address_1"
                                                    autocomplete="street-address"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={stateAddress || ''}
                                                    onChange={e => setStateAddress(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="address_2"
                                                    className="block text-sm font-medium text-gray-700">
                                                    Street address 2
                                                </label>

                                                <input
                                                    type="text"
                                                    name="address_2"
                                                    id="address_2"
                                                    autocomplete="street-address"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={stateAddress2 || ''}
                                                    onChange={e => setStateAddress2(e.target.value)}
                                                />
                                            </div>

                                            {/* <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                                <label
                                                    for="city"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    City
                                                </label>

                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"

                                                />
                                            </div> */}

                                            {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                <label
                                                    for="state"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    State
                                                </label>

                                                <input
                                                    type="text"
                                                    name="state"
                                                    id="state"
                                                    className="mt-1 focus:ring-indigo-500  block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                />
                                            </div> */}

                                            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                                <label
                                                    for="postcode"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Postal Code <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="postcode"
                                                    id="postcode"
                                                    autocomplete="postcode"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={postalCode || ''}
                                                    onChange={e => setPostalCode(e.target.value)}
                                                />
                                            </div>
                                            {/*
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="image"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Image
                                                </label>

                                                <input
                                                    type="file"
                                                    name="image"
                                                    id="image"
                                                    autocomplete="image"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    // value={image}
                                                    onChange={e => {

                                                        setImage(e.target.files[0])
                                                    }}
                                                />

                                            </div> */}
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="gender"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Gender <sup className='text-red-500'>*</sup>
                                                </label>

                                                <select
                                                    id="gender"
                                                    name="gender"
                                                    autocomplete="gender"
                                                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                    value={gender || ''}
                                                    onChange={e => setGender(e.target.value)}
                                                >
                                                    <option>Select Gender</option>
                                                    <option>Male</option>
                                                    <option>Female</option>
                                                    <option>Others</option>
                                                </select>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    for="password"
                                                    className="block text-sm font-medium text-gray-700">
                                                    Passwoard <sup className='text-red-500'>*</sup>
                                                </label>

                                                <input
                                                    type="text"
                                                    name="password"
                                                    id="password"
                                                    autocomplete="street-address"
                                                    className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                    value={password || ''}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-span-6 ">
                                                <label
                                                    for="street_address"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Bio
                                                </label>

                                                <textarea
                                                    name="bio"
                                                    id="bio"
                                                    cols="30"
                                                    rows="5"
                                                    className='mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2'
                                                    value={bio || ''}
                                                    onChange={e => setBio(e.target.value)}
                                                >
                                                </textarea>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="mt-10 sm:mt-0 mb-10">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Educational And Skills
                                        Information</h3>
                                    <p className="mt-1 text-sm text-gray-600">
                                        Use a Authenticate Information.
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <div className="shadow overflow-hidden sm:rounded-md">
                                    <div className="px-4 py-5 bg-white sm:p-6">


                                        {/* {
                                        inputList.map((x, i) =>

                                            <div className="grid grid-cols-4 gap-6 items-center">
                                                <div className="">
                                                    <label for="first_name" className="block text-sm font-medium text-gray-700">Title</label>
                                                    <input type="text" name="title" id="title" autocomplete="given-name" className="mt-1 focus:ring-indigo-500 outline-none focus:border-indigo-500 border p-2 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                        value={x.title}
                                                        onChange={e => handleInputChange(e, i)} />
                                                </div>
                                                <div className="">
                                                    <label for="last_name" className="block text-sm font-medium text-gray-700">institute</label>
                                                    <input type="text" name="institute" id="institute" autocomplete="family-name" className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2" value={x.institute} onChange={e => handleInputChange(e, i)} />
                                                </div>

                                                <div className="">
                                                    <label for="last_name" className="block text-sm font-medium text-gray-700">session</label>
                                                    <input type="text" name="session" id="session" autocomplete="family-name" className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2" value={x.session} onChange={e => handleInputChange(e, i)} />
                                                </div>

                                                {inputList.length !== 1 &&

                                                    <RxCrossCircled className=' w-8 h-8 text-[#292d60]' onClick={() => handleRemoveClick(i)} />


                                                }
                                                {inputList.length - 1 === i &&

                                                    <AiOutlinePlusCircle className=' w-8 h-8 text-[#292d60]' onClick={handleAddClick} />
                                                }

                                            </div>

                                        )} */}


                                        <label
                                            for="experience"
                                            className="block text-sm font-medium text-gray-700 mt-3"
                                        >
                                            Experience <sup className='text-red-500'>*</sup>
                                        </label>

                                        <input
                                            type="text"
                                            name="experience"
                                            id="experience"
                                            autocomplete="family-name"
                                            className="mt-1 focus:ring-indigo-500 block w-1/2 shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2 mb-3"
                                            value={experience || ''}
                                            onChange={e => setExperience(e.target.value)}
                                        />
                                        <h3 className="text-lg font-medium leading-6 text-gray-900 mt-10 mb-10">Skill
                                            Information</h3>
                                        <h1>skills:</h1>
                                        {/* {
                                        skillinput.map((x, i) =>
                                            <div className='flex gap-6 items-center'>
                                                <div className="">
                                                    <input type="text" name="skills" id="skills" autocomplete="family-name" className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2" value={x.name} onChange={e => handleInputskillInputChange(e, i)} />
                                                </div>
                                                {skillinput.length !== 1 &&

                                                    <RxCrossCircled className='h-8 w-8' onClick={() => handleSkillRemoveClick(i)} />


                                                }
                                                {skillinput.length - 1 === i &&

                                                    <AiOutlinePlusCircle className='h-8 w-8' onClick={handleAddSkillClick} />
                                                }




                                            </div>
                                        )

                                    } */}


                                        <div className='flex gap-6 items-center'>
                                            {skills.map((skill, index) => (
                                                <div key={index}>
                                                    <input type="text" name="skills" id="skills"
                                                           autocomplete="family-name"
                                                           className="mt-1 focus:ring-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md outline-none focus:border-indigo-500 border p-2"
                                                           value={skill || ''}
                                                           onChange={(e) => handleSkillsChange(e.target.value, index)}/>
                                                    <button className='py-1 px-3 bg-red-400 text-white mt-4'
                                                            onClick={() => handleskillRemove(index)}>Remove
                                                    </button>
                                                </div>
                                            ))}
                                            <button
                                                className='bg-[#1e2158] py-1 px-3 text-white hover:text-[#1e2158] hover:bg-white duration-300 hover:border border items-center'
                                                onClick={handleskillAdd}>Add
                                            </button>


                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>


                    <div className='flex justify-end space-x-4'>
                        <Link to="/"
                              className='bg-white text-[#292d60] px-10 py-2 rounded-md hover:text-white hover:bg-[#292d60] border border-[#292d60] duration-300'>Cancel</Link>
                        {
                            isLoading ?
                                <>
                                    <button
                                        type="button"
                                        className="flex items-center gap-2 py-2 px-5 text-rose-600"
                                        disabled
                                    >
                                        <svg className="h-4 w-4 animate-spin" xmlns="http://www.w3.org/2000/svg"
                                             fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                                    strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor"
                                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <span> Submitting ... </span>
                                    </button>
                                </>
                                :
                                <>
                                    <button disabled={isLoading}
                                            className='w-20 py-2 bg-indigo-900 hover:bg-indigo-800 text-indigo-50 font-medium rounded-md'>
                                        Submit
                                    </button>
                                </>
                        }
                    </div>
                </form>
            </div>
        </>
    );
};

export default CreateInstructor;