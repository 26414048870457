import React from 'react';
import { FaArrowRight, FaHome, FaShareAlt } from 'react-icons/fa';
import { useSearchParams } from 'react-router-dom';
import course1 from '../../assets/images/course1.png';
import course2 from '../../assets/images/course2.png';
import course3 from '../../assets/images/course3.jpeg';
import course4 from '../../assets/images/course4.jpeg';
import SEO from '../../components/ui/SEO';
import { useGetSearchQuery } from '../../features/search/searchAPI';
import CoursePageItems from './CoursePageItems';
import { useGetAllCoursesQuery } from '../../features/courses/coursesAPI';
import { useState } from 'react';
import AllCourseItem from './AllCourseItem';
import CourseSkeleton from '../../components/ui/Skeleton/CourseSkeleton';
import Pagination from '../../components/ui/Pagination';


const CoursePage = () => {

    const [searchQuery, setSearchQuery] = useSearchParams();
    const [page, setPage] = useState(1);
    const searchfor = searchQuery.get('search')

    // all courses data
    const { data: allCourseData, isLoading: courseLoading, isError: courseError } = useGetAllCoursesQuery(page);
    const { data: responseData } = allCourseData || {};
    const { data: allCourses, meta: pages } = responseData || {};

    const perPageItems = pages?.per_page;
    const totalItem = pages?.total;
    const currentPage = pages?.current_page;
    const lastPage = pages?.last_page;
    const totalPage = lastPage;

    const handlePageChange = () => {
        setPage((prev) => prev + 1);
    }

    function previousClick() {
        setPage(page - 1)
    }

    const handlePaginate = (page) => {
        setPage(page)
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
    }


    // search Result load
    const { data, isLoading, isError } = useGetSearchQuery(searchQuery.get('search'));
    const { data: SearchResult } = data || {};

    console.log(SearchResult);
    console.log(searchfor);
    let allCoursesItems = null;
    let searchCourses = null;

    if (isLoading) {
        searchCourses = allCoursesItems = <>

            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>
            <div className='grid grid-cols-4 gap-10'> <CourseSkeleton /></div>



        </>
        // allCoursesItems = <CourseSkeleton/>
    } else if (!isLoading && isError) {
        searchCourses = <p>There was an error!</p>
        allCoursesItems = <p>There was an error!</p>
    } else if (!isLoading && !isError && allCourses?.length === 0) {
        allCoursesItems = <div className='text-center text-red-200 text-lg p-4 font-medium'>No items found!</div>;
    } else if ((!isLoading && !isError && data?.status === 1) || (!isLoading && !isError && allCourses?.length > 0)) {
        searchCourses = SearchResult.map(course => <CoursePageItems key={course.id} course={course} />
        )

        allCoursesItems = allCourses?.map(allCourse => <AllCourseItem key={allCourse.id} allCourse={allCourse} />);

    }

    console.log(perPageItems)

    return (
        <>
            <SEO
                title="Course Page"
                url="/course-page"
            />
            <div className='pt-20 max-w-[1265px] mx-auto'>
                <div className='px-10 divide-y'>
                    {
                        searchfor === null ?
                            <>
                                <h1 className='text-xl font-semibold mb-5'> All Courses</h1>
                            </>
                            :
                            <>
                                <h1 className='text-xl font-semibold mb-5'> Search Results for "{searchfor}"</h1>
                            </>
                    }
                    {
                        (searchfor !== null) && (SearchResult?.length === 0 || '') ?
                            <>
                                <h1 className='text-xl font-semibold'>No items founds! </h1>
                            </>
                            :

                            (SearchResult?.length > 0) &&
                            <h1 className='text-xl font-semibold'>{SearchResult?.length} Items Found!</h1>



                    }
                </div>
                <div className=' grid sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-10 px-10'>
                    {
                        (searchfor !== null) && (SearchResult?.length >= 0 || '') ?
                            searchCourses
                            :
                            allCoursesItems
                    }


                </div>

                {
                    (!(searchfor !== null) && (SearchResult?.length === 0 || '') && (allCourses?.length > 0)) &&
                    (
                        perPageItems < totalItem &&
                        <Pagination currentPage={currentPage} perPage={perPageItems} totalItems={totalItem} handlePaginate={handlePaginate}/>
                    )
                }

            </div>
        </>
    );
};

export default CoursePage;