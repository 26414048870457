import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BsFiletypePdf } from 'react-icons/bs';
import { FiPlayCircle } from 'react-icons/fi';

const Lesson = ({lesson}) => {

    const { slug, lessonId } = useParams();
    const { id, topicId, title, duration, isVideo, isAttachment } = lesson || {};
    const [isActive, setIsActive] = useState(false);
    




    useEffect(() => {
        if (lessonId == id) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [id, lessonId]);





    return (
        <li>
            <Link 
                to={`/user/account/courses/${slug}/${topicId}/playlists/${id}`}
                className={`${isActive ? 'bg-blue-100 text-blue-900' : 'text-gray-600'} flex items-start gap-2 py-3 px-5 text-sm hover:bg-indigo-100 hover:text-blue-900 cursor-pointer`}
            >
                <div className='w-5'>
                    {
                        isVideo 
                        ? <FiPlayCircle className={`text-xl ${isActive ? 'text-rose-600 animate-pulse' : ''}`} />
                        : (
                            isAttachment
                            ?
                                <BsFiletypePdf className={`text-xl ${isActive ? 'text-rose-600 animate-pulse' : ''}`} />
                            : <></>
                        )
                    }
                    
                </div>
                <div className='flex flex-col gap-1.5'>
                    <h5 className='font-medium'>{title}</h5>
                    <small>{duration} Minutes</small>
                </div>
            </Link>
        </li>
    )
}

export default Lesson