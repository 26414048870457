import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { useGetHighlightedCourseCategoriesQuery } from '../../../features/courseCategories/courseCategoriesAPI';
import LandingCategoryItem from './LandingCategoryItem';
import CourseSkeleton from '../../ui/Skeleton/CourseSkeleton';
import './landingCategorie.module.css';

const LandingCategories = () => {
    const { data, isLoading, isError } = useGetHighlightedCourseCategoriesQuery();
    const { data: responseData } = data || {};
    const { data: highlightedCourseCategories } = responseData || {};

    let courseCategoryItems = null;

    if (isLoading) {
        courseCategoryItems = (
            <div className="row container">
                {Array(8).fill(null).map((_, index) => (
                    <div key={index} className="col-lg-2 col-md-2 col-sm-6 col-xs-6 mb-4">
                        <CourseSkeleton />
                    </div>
                ))}
            </div>
        );
    } else if (!isLoading && isError) {
        courseCategoryItems = (
            <div className="text-center text-yellow-100 text-lg p-4 font-medium">
                Something went wrong!
            </div>
        );
    } else if (!isLoading && !isError && highlightedCourseCategories?.length === 0) {
        courseCategoryItems = (
            <div className="text-center text-red-200 text-lg p-4 font-medium">
                No items found!
            </div>
        );
    } else if (!isLoading && !isError && highlightedCourseCategories?.length > 0) {
        courseCategoryItems = (
            <div className="row container">
                {highlightedCourseCategories.slice(0, 12).map((category) => (
                    <div key={category.id} className="col-lg-2 col-md-4 col-12">
                        <LandingCategoryItem category={category} />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="container">
            <div className="titleCategories pb-7 ml-5 text-[#292d60]">
                <h1 className="pt-10 border-0 ml-5 titleCategories uppercase font-semibold mb-5" >
                    Categories
                    <a className="float-right mr-5 font-normal normal-case" href="/category-page">
                        View All <FaArrowRight className="inline h-3 mb-1" />
                    </a>
                </h1>
                <div className="mx-3 containers">{courseCategoryItems}</div>
            </div>
        </div>
    );
};

export default LandingCategories;
