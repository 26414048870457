import React, {useEffect, useState} from 'react';
import SEO from '../../components/ui/SEO';
import style from './ReturnAndRefund.module.css';
import DOMPurify from 'dompurify';

const ReturnAndRefundPolicy = () => {

    const [data, setData] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        fetch(`${baseUrl}/api/v1/return-and-refund`)
            .then(response => response.json())
            .then(data => setData(data?.data))
            .catch(error => console.log(error));
    }, []);


    return (
        <>
            <SEO
                title="Return And Refund Policy"
                url="/return-and-refund-policy"
            />
            <div className={`mt-10 container m-auto max-w-[1260px] px-5`}>

                <div className={` ${style.size}`}
                     dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.description)}}
                />

            </div>
        </>
    );
};

export default ReturnAndRefundPolicy;