import React from 'react';
import {FaShareAlt} from 'react-icons/fa';
import course1 from '../../assets/images/course1.png';
import course2 from '../../assets/images/course2.png';
import course3 from '../../assets/images/course3.jpeg';
import course4 from '../../assets/images/course4.jpeg';
import course5 from '../../assets/images/course5.jpeg';

const UserCourse = () => {
    return (
        <div>
            <div className='pt-20'>
            <div className='px-20 divide-y'>
                <div className='flex space-x-3 mt-10'>
                  <h1 className='text-xl font-semibold'>My Course</h1>
                </div>

            </div>
            <div className='cards grid sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-20 px-10'>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course1})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course2})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course3})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course4})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course5})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course1})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course1})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course1})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className=' block bg-transparent w-[95%] bg-no-repeat bg-center mx-auto h-[212px] bg-cover hover:scale-105 duration-500 ease-in-out rounded-2xl' style={{ backgroundImage: `url(${course1})` }}>

                    <a href="/" className="text-white group">
                        <div className='pt-5'>
                            <p className='mr-3  text-white bg-[#192b3d] block w-[90px] pl-2 pr-2 py-1 rounded-lg'>premiem</p>
                        </div>
                        <div className=''>
                            <div className='group-hover:bg-gradient-to-t from-black mt-[7rem] group-hover:rounded-2xl'>
                                <div className='invisible group-hover:visible flex justify-between mx-auto px-3'>
                                    <a className='ml-2 pl-2 pr-2 py-1 rounded-xl mb-4 bg-[#192b3d]' href="/">Watch Now</a>
                                    <a className='py-2' href="/"><FaShareAlt /></a>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        </div>
    );
};

export default UserCourse;