import React, {useEffect, useState} from 'react';
import demoAvater from '../../assets/images/profile-avater.jpg';
import {
    useGetProfileImageQuery,
    useGetProfileInfoQuery,
    useUpdateProfileInfoMutation,
    useUpdateProfileMutation
} from '../../features/userProfile/userProfileAPI';


const UserProfile = () => {
  const [certificateNameInput, setCertificateNameInput] = useState('');
  const [firstName, setFirstNameInput] = useState('');
  const [LastName, setLastNameInput] = useState('');
  const [addressOne, setAddressOne] = useState('');
  const [addressTwo, setAddressTwo] = useState('');
  const [postcodeinput, setPostCode] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [inputImage, setInputImage] = useState(null);
  let timeoutId;

  
  // const { user } = useSelector(state => state.auth);
  // const { first_name, last_name, username, email, phone,address_1,address_2,postcode } = user || {};
  // const fullName = first_name + ' ' + last_name;

  const [updateProfile, { data, isLoading, isError, error }] = useUpdateProfileMutation();
  const { data: getImage,isLoading:profileImageLoading, refetch } = useGetProfileImageQuery();
  const [updateProfileInfo, { data:profileInfo, isLoading:updateProfileLoading }] = useUpdateProfileInfoMutation();
  const { data: getInfo, refetch:profileInfoFetch } = useGetProfileInfoQuery();
  const {first_name:firstNameInfo, last_name:LastNameInfo, username:userNameInfo, email:emailInfo, phone:phoneInfo, address_1:addressOneInfo,address_2:addressTwoInfo, postcode:postCodeInfo} = getInfo?.data || {};
  const fullNameInfo = `${firstNameInfo} ${LastNameInfo}`;
  
    
 useEffect(()=> {
  setFirstNameInput(firstNameInfo)
  setLastNameInput(LastNameInfo)
  setAddressOne(addressOneInfo)
  setAddressTwo(addressTwoInfo)
  setPostCode(postCodeInfo)
  setCertificateNameInput(fullNameInfo);
  setPhoneInput(phoneInfo);
 },[firstNameInfo,LastNameInfo,addressOneInfo, addressTwoInfo,postCodeInfo,fullNameInfo,phoneInfo])
 
  console.log(data)
  const handleRefetch = () => {
    refetch();
  };

  const handleProfileInfoRefetch = () => {
    profileInfoFetch();
  };


  useEffect(()=> {
    if(data?.status === 1){

      setInputImage('');
      handleRefetch();
    }
  },[data?.status])

  console.log(data?.message)
  console.log(getImage?.data?.image)
  console.log(profileInfo)


  

  console.log(getInfo?.data)

  

  function handleFirstNameChange(event) {
    clearTimeout(timeoutId);
    setFirstNameInput(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }
  function handleLastNameChange(event) {
    clearTimeout(timeoutId);
    setLastNameInput(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }
  function handleCertificateNameInputChange(event) {
    clearTimeout(timeoutId);
    setCertificateNameInput(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }

  function handlePhoneInputChange(event) {
    clearTimeout(timeoutId);
    setPhoneInput(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }
  function handleAddressOneChange(event) {
    clearTimeout(timeoutId);
    setAddressOne(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }
  function handleAddressTwoChange(event) {
    clearTimeout(timeoutId);
    setAddressTwo(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }
  function handlePostCodeChange(event) {
    clearTimeout(timeoutId);
    setPostCode(event.target.value);
    timeoutId = setTimeout(() => {
      console.log('Delayed update');
    }, 500);
  }


  function handleImageChange(event) {
    setInputImage(event.target.files[0]);
  }


  const formData = new FormData();
  formData.append('image', inputImage);
  function handleSubmit(event) {

    event.preventDefault();

    updateProfile(
      formData
    );
    
    

  }
  function handleSubmitInfo(event) {

    event.preventDefault();

    updateProfileInfo(
      {
        phone: phoneInput,
        first_name: firstName,
        last_name:LastName,
        address_1: addressOne,
        address_2: addressTwo,
        postcode: postcodeinput


      }

    );
    
  }


  
 

  const userImage = process.env.REACT_APP_BASE_URL + '/' + getImage?.data?.image;
  console.log(userImage);
  return (
    <>
      <div className="h-full mx-20 mt-10">
        <div className="block md:flex">
          <div className="lg:w-[30%] h-96 md:w-2/5 p-4 sm:p-6 lg:p-8 bg-white rounded-lg">
            <h1 className="text-center text-2xl font-semibold">{fullNameInfo}</h1>
            <div className="w-full p-8 mx-2 flex justify-center">
              {
                isLoading ?
                  <>
                    <p>upload Image loading...</p>
                  </>
                  :
                 (profileImageLoading ? <p>profile image loading..</p> :  <>
                 <img
                   id="showImage"
                   className="max-w-xs w-fit h-52 items-center border"
                   src={(getImage?.data?.image === 'assets/img/avatar.png' || getImage?.data?.image === null) ? demoAvater : userImage}
                   alt=""
                 />
               </>)
              }

            </div>
            <form onSubmit={handleSubmit}>
              <input type="file" onChange={handleImageChange} />
              <button className="w-full bg-[#1e2158] text-white px-4 py-1" disabled={isLoading}>
                {isLoading ? 'Uploading...' : 'Upload Profile Picture'}
              </button>
              {isError && <div>Error: {error?.message}</div>}
            </form>
          </div>
          <form onSubmit={handleSubmitInfo} className="w-full bg-white md:w-3/5 px-3 py-2 lg:ml-4">
            <div className="px-4 py-4">
              <div className="pb-4">
                <label htmlFor="first_name" className="font-semibold text-gray-700 block pb-1">First Name</label>
                <input id="first_name" className="border-1 rounded-r px-4 py-2 w-full border" type="text" value={firstName || firstNameInfo|| ''} onChange={handleFirstNameChange}/>
              </div>
              <div className="pb-4">
                <label htmlFor="last_name" className="font-semibold text-gray-700 block pb-1">Last Name</label>
                <input id="last_name" className="border-1 rounded-r px-4 py-2 w-full border" type="text" value={LastName || LastNameInfo || ''} onChange={handleLastNameChange}/>
              </div>
              <div className="pb-4">
                <label htmlFor="username" className="font-semibold text-gray-700 block pb-1">Username</label>
                <input disabled id="username" className="border-1 rounded-r px-4 py-2 w-full border" type="text" value={ userNameInfo || ''} />
              </div>
              <div className="pb-4">
                <label htmlFor="certificateName" className="font-semibold text-gray-700 block pb-1">Certificate Name</label>
                <input disabled id="certificateName" className="border-1 rounded-r px-4 py-2 w-full border" type="text" value={certificateNameInput || fullNameInfo || ''} onChange={handleCertificateNameInputChange} />
                <small>certificate name given from first name and last name</small>
              </div>
              <div className="pb-4">
                <label htmlFor="email" className="font-semibold text-gray-700 block pb-1">Email</label>
                <input disabled id="email" className="border-1 rounded-r px-4 py-2 w-full border" type="email" value={ emailInfo || ''} />
              </div>
              <div className="pb-4">
                <label htmlFor="phone" className="font-semibold text-gray-700 block pb-1">Phone</label>
                <input id="phone" className="border-1 px-4 py-2 w-full border" type="number" value={phoneInput || phoneInfo || ''} onChange={handlePhoneInputChange} maxLength={10} />
              </div>
              <div className="pb-4">
                <label htmlFor="address_1" className="font-semibold text-gray-700 block pb-1">Address 1</label>
                <input id="address_1" className="border-1 px-4 py-2 w-full border" type="text" value={addressOne || addressOneInfo || ''} onChange={handleAddressOneChange}  />
              </div>
              <div className="pb-4">
                <label htmlFor="address_2" className="font-semibold text-gray-700 block pb-1">Address 2</label>
                <input id="address_2" className="border-1 px-4 py-2 w-full border" type="text" value={addressTwo ||addressTwoInfo || ''} onChange={handleAddressTwoChange}  />
              </div>
              <div className="pb-4">
                <label htmlFor="postcode" className="font-semibold text-gray-700 block pb-1">Post Code</label>
                <input id="postcode" className="border-1 px-4 py-2 w-full border" type="text" value={postcodeinput || postCodeInfo || ''}  onChange={handlePostCodeChange}  />
              </div>
              <div>
                <button className="bg-[#1e2158] text-white px-3 py-2 rounded-md" type="submit" disabled={updateProfileLoading}>
                  {updateProfileLoading ? 'Updating...' : 'Update'}
                </button>
              </div>
              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
