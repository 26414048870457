import React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import axios from 'axios';
import { useCookies } from 'react-cookie';


const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const [cookies] = useCookies(['EduTvAuth']);

    const auth = cookies?.EduTvAuth;
    const token = auth?.accessToken;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const handleSubmit = async (event) => {
        event.preventDefault();

        const schema = Yup.object().shape({
            oldPassword: Yup.string().required('Old password is required'),
            newPassword: Yup.string()
                .required('New password is required')
                .min(6, 'New password must be at least 6 characters long'),
            confirmPassword: Yup.string()
                .required('Confirm password is required')
                .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
        });

        try {
            await schema.validate({ oldPassword, newPassword, confirmPassword });
            await axios.post(`${baseUrl}/api/v1/account/change-password`,
                {
                    oldPassword,
                    newPassword,
                    confirmPassword
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            setErrorMessage('');
            alert('Password changed successfully!');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
        } catch (error) {
            if (error.response) {
                console.log(error.response.data.error);
                setErrorMessage(error.response.data.error);
            } else {
                setErrorMessage(error.errors[0]);
                console.log(error.createError);
            }
        }
        setTimeout(() => setErrorMessage(''), 5000);
    };




    return (
        <React.Fragment>
            <div className='h-screen w-screen'>
                <form onSubmit={handleSubmit}>

                    <div className='mx-80 my-10 w-[30%] h-auto bg-white'>
                        <div className="w-full bg-white  px-3 py-2 lg:ml-4 ">
                            <div className=" py-4">
                                {errorMessage && <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                                    <strong class="font-bold"></strong>
                                    <span class="block sm:inline">{errorMessage}</span>
                                 
                                </div>}
                                <div className="pb-4">
                                    <label htmlFor="oldPassword" className="font-semibold text-gray-700 block pb-1">Old Password</label>
                                    <input
                                        id="oldPassword"
                                        type="password"
                                        value={oldPassword}
                                        onChange={(event) => setOldPassword(event.target.value)}
                                        className="border-1 rounded-r px-4 py-2 w-full border"
                                    />

                                </div>
                                <div className="pb-4">
                                    <label htmlFor="newPassword" className="font-semibold text-gray-700 block pb-1">New Password</label>
                                    <input
                                        id="newPassowrd"
                                        className="border-1  rounded-r px-4 py-2 w-full border"
                                        type="password"
                                        value={newPassword}
                                        onChange={(event) => setNewPassword(event.target.value)}
                                    />

                                </div>
                                <div className="pb-4">
                                    <label htmlFor="confirmPassword" className="font-semibold text-gray-700 block pb-1">Confirm Password</label>
                                    <input
                                        id="confirmNewPassword"
                                        className="border-1  rounded-r px-4 py-2 w-full border"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(event) => setConfirmPassword(event.target.value)}
                                    />

                                </div>

                                <div className="">
                                    <button type='submit' className='bg-[#1e2158] text-white w-full px-3 py-2 rounded-md'>

                                        Update Password
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </React.Fragment>
    );
};

export default ChangePassword;