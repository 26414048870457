import React from 'react'
import { BiRightArrow } from "react-icons/bi";
import { Link } from 'react-router-dom';
import handleCharacterLimit from '../../../utils/handleCharacterLimit';


const CourseItem = ({ course }) => {


    console.log(course);
    const { title, slug, thumbnail_image: thumbnailImage } = course || {};

    const thumbnail = process.env.REACT_APP_BASE_URL + '/' + thumbnailImage;



    return (
        <div className="flex flex-col justify-between bg-white h-[290px] rounded-md mt-8 shadow-lg">
            <Link
                to={`/user/account/courses/${slug}`}
                className="flex justify-center items-center leading-none"
            >
                <img
                    src={thumbnail}
                    alt="pic"
                    className="h-40 w-[89%] mx-auto rounded-md mt-6 transform -translate-y-10 hover:-translate-y-2 transition duration-700"
                />
            </Link>
            <div className="p-3">
                <Link
                    to={`/user/account/courses/${slug}`}
                    className="text tracking-tighter text-gray-600"
                >
                    {handleCharacterLimit(title, 50)}
                </Link>
                <Link
                    to={`/user/account/courses/${slug}`}
                    className='flex items-center justify-center gap-1 bg-blue-100 hover:bg-blue-200 text-xs py-1.5 text-center rounded-xl mt-2'
                >
                    Access Course <BiRightArrow />
                </Link>
            </div>
        </div>
    )
}

export default CourseItem